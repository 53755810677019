document.addEventListener('turbolinks:load', event => {
  let hiddentargets = document.querySelectorAll('.hiddentarget')
  hiddentargets.forEach(hiddentarget => {
    hiddentarget.setAttribute('target', '_self')
  })

  let hiddeninfoinsts = document.querySelectorAll('.info-institutional')
  let hiddeninfoinstdetails = document.querySelectorAll('.info-institutional-details')
  hiddeninfoinsts.forEach(hidhiddeninfoinst => {
    hidhiddeninfoinst.addEventListener('click', event => {
      hiddeninfoinstdetails.forEach(hiddeninfoinstdetail => {
        hiddeninfoinstdetail.classList.toggle('hiddenFocus')
      })
    })
  })

  let arrowTop = document.querySelector('#to-top')

  if (!arrowTop) return
  arrowTop.addEventListener('click', () =>
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  )

  const addOtherBorder = () => {
    let widthSection = document.documentElement.clientWidth
    let listCategory = document.querySelectorAll('.js-list .category-line')
    let secondList = document.querySelectorAll('.js-border-list .category-line')

    if (widthSection > 1024) {
      let lastItem = Array.from(listCategory).slice(-3)
      lastItem.forEach(item => {
        item.classList.remove('border-hidden')
      })

      let lastElement = Array.from(secondList).slice(-3)
      lastElement.forEach(elem => {
        elem.classList.add('border-hidden')
      })
    } else {
      let elem = listCategory[listCategory.length - 1]
      elem.classList.remove('border-hidden')

      let lastElem = secondList[secondList.length - 1]
      lastElem.classList.add('border-hidden')
    }
  }

  const addBorder = () => {
    let widthSection = document.documentElement.clientWidth
    let listCategory = document.querySelectorAll('.js-list .category-line')
    let secondList = document.querySelectorAll('.js-border-list .category-line')

    if (!listCategory.length || !secondList.length) return

    if (widthSection > 1024) {
      let lastItem = Array.from(listCategory).slice(-3)
      lastItem.forEach(item => {
        item.classList.add('border-hidden')
      })

      let lastElement = Array.from(secondList).slice(-3)
      lastElement.forEach(elem => {
        elem.classList.add('border-hidden')
      })
    } else {
      let elem = listCategory[listCategory.length - 1]
      elem.classList.add('border-hidden')

      let lastElem = secondList[secondList.length - 1]
      lastElem.classList.add('border-hidden')
    }
  }

  addBorder()

  let buttonPress = document.querySelector('#js-all-categories')
  if (buttonPress) {
    buttonPress.addEventListener('click', () => {
      let content = document.querySelector('#js-section-categories')
      let hiddenList = document.querySelector('.js-rest-categories')
      let rows = document.querySelector('#js-content-categories')

      if (hiddenList.classList.contains('hidden')) {
        hiddenList.classList.remove('hidden')
        buttonPress.querySelector('span').textContent = 'Ver menos categorías'
        buttonPress.querySelector('.js-icon-minus').classList.remove('hidden')
        buttonPress.querySelector('.js-icon-plus').classList.add('hidden')
        rows.classList.add('remove-spacing')

        window.scroll({
          top: rows.scrollHeight + content.scrollHeight,
          behavior: 'smooth'
        })

        addOtherBorder()
      } else {
        hiddenList.classList.add('hidden')
        buttonPress.querySelector('span').textContent = 'Ver más categorías'
        buttonPress.querySelector('.js-icon-minus').classList.add('hidden')
        buttonPress.querySelector('.js-icon-plus').classList.remove('hidden')
        window.scroll({
          top: rows.scrollHeight + content.scrollHeight,
          behavior: 'smooth'
        })

        addBorder()
      }
    })
  }

  import('../components/institution_horizontal_scroll')
})
